
#industry {
    padding-top: 5%;
    padding-bottom: 5%;
}

.industry {
    height: 550px;
    width: 350px;
    cursor: pointer;
    position: relative;
    margin-bottom: 32px;
    overflow: hidden;
}

.industry .industry-img {
    width: 100%;
    height: 100%;
    background-size: cover !important;
}
.industry:hover .industry-img,
.industry:focus .industry-img {
    transform: scale(1.2);
    transition: all .5s;
}

.industry .industry-img::before {
    content: '';
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 1);
    opacity: 40%;
    transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s linear;
    z-index: 1;
}

.industry:hover .industry-img::before,
.industry:focus .industry-img::before {
    height: 100%;
    width: 100%;
    opacity: 100%;
    background: rgb(173, 173, 173);
    background: -moz-linear-gradient(31deg, rgba(173, 173, 173, 0.5326505602240896) 0%, rgba(255, 153, 0, 0.8799894957983193) 100%);
    background: -webkit-linear-gradient(31deg, rgba(173, 173, 173, 0.5326505602240896) 0%, rgba(255, 153, 0, 0.8799894957983193) 100%);
    background: linear-gradient(31deg, rgba(173, 173, 173, 0.5326505602240896) 0%, rgba(255, 153, 0, 0.8799894957983193) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#adadad", endColorstr="#ff9900", GradientType=1);
    transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);
}

.industry:hover h4 span {
    display: block;
    margin-top: 20px;
    transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);
}

.industry h4 {
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    padding: 76% 40px 0;
    z-index: 100;
    position: relative;
}

.industry:hover h4,
.industry:focus h4 {
    padding: 50% 40px 0;
}

.industry h4 span {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    display: none;
}

@media screen and (max-width:1024px) {
    .industry {
        height:420px;
        width: 294px;
    }
}
@media screen and (max-width:768px) {
    .industry {
        height:540px;
        width: 100%;
        margin: 24px auto;
    }

}
@media screen and (max-width:425px) {
    .industry {
        height:480px;
    }
    .industry h4 {
        padding:60% 40px 0;
    }
}

@media screen and (min-width:769px) {
    .hero-conf-mobile {
        display: none;
    }
}



#benefits .container {
    z-index: 10;
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
}

#benefits .icon {
    font-size: 22px;
}

#benefits .icon span {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    font-style: italic;
}

#benefits .icon img {
    margin-bottom: 30px;
}
.icon img {
    max-width: 75%;
}

.icon {
    color: #232F3E;
    font-family: "neue-haas-grotesk-display", sans-serif;
    font-size: 24px;
    line-height: 32px;
    margin-left: 30px;
}

.icon span {
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
}

.icons .w-100 {
    padding-bottom: 100px;
}

.icons .h3 {
    margin-bottom: 100px;
}

.icons a.button {
    margin: 50px auto 100px;
    display: inline-block;
}
@media screen and (max-width:992px) {
    .icon {
        margin-left: 0;
        margin-bottom: 32px;
    }
    .icons .h3 {
        margin-bottom: 50px;
    }
    .w-100 {
        display: none;
    }
}

.hero {
    position: relative;
    background: url(assets/hero.png) no-repeat;
    background-size: 250px 400px;
    background-position: top left;

}
.hero:before {
    content: '';
    background: url(assets/hero-man.png) no-repeat;
    background-size: 100%;
    height: 750px;
    width: 750px;
    bottom: -10px;
    right: 30%;
    position: absolute;
}

.hero-container {
    max-width: 100%;
    padding: 0;
    width: 100%;
}

.hero-logo {
    padding: 5%;
    width: 100%;
}

.hero-logo img {
    max-width: 285px;
    margin-bottom:20px;
}

.hero-content {
    padding: 10%;
    max-width: 65%;
    z-index: 10;
    position: relative;
}

.hero h2 {
    font-size: 30px;
}

.hero-content p {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
}

.mobile-hero {
    display: none;
}

.mobile-hero img {
    width: 100%;
    display: block;
    margin: 0 auto;
}


@media screen and (max-width:1440px){
    .hero-content {
        max-width: 75%;
    }
    .hero:before {
        right:20%;
    }
}
@media screen and (max-width:1024px) {
    .hero-content {
        padding:30% 5%;
    }
    h1 {
        font-size:48px;
    }
    .hero-content p {
        font-size:20px;
        margin-bottom:24px;
    }
    .hero:before {
        right:10%
    }
}
@media screen and (max-width:990px) {
    h1 {
        font-size: 60px;
        line-height: 1.2;
    }
    .hero:before {
        display: none;
    }
    .mobile-hero {
        position: relative;
        display: block;
    }
    .hero-content {
        padding:10% 5%;
        max-width:100%;
    }
}

@media screen and (max-width:768px) {
    .hero {
        background-size:200%;
    }
}

@media screen and (max-width:425px) {
    h1 {
        font-size:30px;
    }
    .hero {
        background:none;
    }
    .hero-logo {
        margin: 16px auto 0;
        display: block;
    }
    .mobile-hero:before {
        right:-115px;
    }

}
